import { Link } from "gatsby";
import React from "react";

export default function BlogPost({ date, title, html, link }) {
  return (
    <div className="prose">
      <div className="mb-1 text-sm">
        <time dateTime={date} className="text-gray-500">
          {date}
        </time>
      </div>
      <Link to={link}>
        <h1>{title}</h1>
      </Link>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}
